
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/order/[group]/[mid]",
      function () {
        return require("private-next-pages/order/[group]/[mid].jsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/order/[group]/[mid]"])
      });
    }
  