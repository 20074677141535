import React from 'react';
import MenuPage from '@artemis/containers/Menu';
import { loadResourceSet } from '@artemis/store/resourceSet/slice';
import { loadMenu } from '@artemis/store/menu/slice';
import { loadMerchantData } from '@artemis/store/merchant/slice';
import commonStrings from '@artemis/utils/common-strings.json';
import {
  MENU_DEBUG_PARAM,
  MENU_DRAFT_ID_PARAM,
  MENU_ITEM_ID_QUERY_PARAM,
  MERCHANT_GROUP_REFERER_PARAM,
  MID_PARAM,
} from '@artemis/utils/query/constants';
import { getCheckoutCodeFromQuery } from '@artemis/utils/promo';
import { queryCheck } from '@artemis/utils/query/useQueryCheck';

const Menu = props => <MenuPage {...props} />;

// This gets called on every request
Menu.getInitialProps = async ctx => {
  const { contentfulLocale, isServer, query, store } = ctx;
  // Fetch data from external API
  if (isServer) {
    const debug = queryCheck(query, MENU_DEBUG_PARAM);
    const {
      [MID_PARAM]: externalId,
      [MERCHANT_GROUP_REFERER_PARAM]: brandExternalId,
      [MENU_DRAFT_ID_PARAM]: menuDraftId,
      [MENU_ITEM_ID_QUERY_PARAM]: includeChoicesForItemId,
    } = query;
    store.dispatch(
      loadMerchantData({
        externalId,
        brandExternalId,
        cartCheckoutCode: getCheckoutCodeFromQuery(query),
      }),
    );
    store.dispatch(
      loadMenu({ externalId, debug, menuDraftId, includeChoicesForItemId }),
    );
    store.dispatch(
      loadResourceSet({
        name: [
          'brand',
          'cart',
          'delivery',
          'downloadApp',
          'groupOrder',
          'merchantState',
          'promo',
          ...commonStrings,
        ],
        locale: contentfulLocale,
      }),
    );
  }
  return {};
};

export default Menu;
